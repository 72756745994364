import { Spinner } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import storage from '@/utils/storage';
import greetingTime from 'greeting-time';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '../api/getAnalytics';
import { CurrentIssues } from '../components/CurrentIssues';

export const Dashboard = () => {
  const [greeting, setGreeting] = useState();
  const { t } = useTranslation();

  const user = storage.getUser();

  useEffect(() => {
    setGreeting(greetingTime(new Date()));
  }, []);

  const analyticsQuery = useAnalytics();

  if (analyticsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  if (!analyticsQuery.data) return null;

  return (
    <ContentLayout>
      <div className="container text-center text-gray-g6 mt-5 mb-5">
        {t('hello')}{' '}
        <span role="img" aria-label="greeting">
          👋🏼
        </span>
        , {greeting} {user.firstName ? user.firstName : 'there'}!
      </div>
      <div className="container grid justify-center gap-4 content-end grid-flow-col auto-cols-max mb-5">
        <div className="bg-purple dark:bg-gray-g4 h-130 w-230 rounded-2xl p-8 grid justify-items-stretch">
          <div className="font-weight-600 text-white text-lg justify-self-center">
            {analyticsQuery.data.clients}
          </div>
          <div className="font-weight-600 text-white text-base justify-self-center">
            {t('customers')}
          </div>
        </div>

        <div className="bg-blue dark:bg-gray-g6 h-130 w-230 rounded-2xl p-8 grid justify-items-stretch">
          <div className="font-weight-600 text-white text-lg justify-self-center">
            {analyticsQuery.data.vendors}
          </div>
          <div className="font-weight-600 text-white text-base justify-self-center">
            {t('vendors')}
          </div>
        </div>

        <div className="bg-yellow dark:bg-gray-g5 h-130 w-230 rounded-2xl p-8 grid justify-items-stretch">
          <div className="font-weight-600 text-white text-lg justify-self-center">
            {analyticsQuery.data.orders}
          </div>
          <div className="font-weight-600 text-white text-base justify-self-center">
            {t('orders')}
          </div>
        </div>
      </div>

      <div className="mb-2 dark:text-gray-g8">{t('currentIssues')}</div>
      <div className="border border-gray-g9 dark:border-gray-g5 rounded-2xl px-4">
        <CurrentIssues />
      </div>
    </ContentLayout>
  );
};
