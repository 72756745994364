import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';

export const getAnalytics = () => {
  return axios.get('/home');
};

export const useAnalytics = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['home'],
    queryFn: () => getAnalytics(),
  });
};
