import { Table, Spinner, Link } from '@/components/Elements';
import { useTranslation } from 'react-i18next';

import { useIssues } from '../api/getIssues';

export const CurrentIssues = () => {
  const issuesQuery = useIssues();

  const { t } = useTranslation();

  if (issuesQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  // if no issues found, return message from server
  if (!issuesQuery.data.issues) {
    return (
      <div className="w-full h-48 flex justify-center items-center">{issuesQuery.data.message}</div>
    );
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  return (
    <Table
      data={issuesQuery.data.issues.slice(0, 10).filter((issue) => {
        //   filter by assigneeId & OPEN
        return issue.status === 'OPEN';
      })}
      columns={[
        {
          title: '',
          field: 'title',
          Cell({ entry: { id, title } }) {
            return (
              <Link to={`/app/issues/${id}`}>
                <span className="text-xs text-gray-g6">
                  {t('issue')} #{id}
                </span>
                <br />
                <span className="text-orange-o10 dark:text-white break-words truncate">
                  {truncateString(title, 50)}
                </span>
              </Link>
            );
          },
        },
        {
          title: '',
          field: 'assignee',
        },
        {
          title: '',
          field: 'status',
          Cell({ entry: { status } }) {
            return (
              <span
                className={`px-2 py-1 rounded-2xl text-xs ${
                  (status === 'OPEN' && 'bg-states-visited') ||
                  (status === 'RESOLVED' && 'bg-states-success') ||
                  (status === 'CLOSED' && 'bg-states-danger')
                } text-white`}
              >
                {status}
              </span>
            );
          },
        },
        {
          title: '',
          field: 'appIssueFrom',
          Cell({ entry: { appIssueFrom } }) {
            return <span className="text-gray-g6 dark:text-gray-g5">{appIssueFrom}</span>;
          },
        },
      ]}
    />
  );
};
