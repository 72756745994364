import { Spinner } from '@/components/Elements';
import { loginWithEmailAndPassword, registerWithCompanyEmail } from '@/features/auth';
import storage from '@/utils/storage';
import { initReactQueryAuth } from 'react-query-auth';

async function handleUserResponse(data) {
  const { token, user } = data;
  storage.setToken(token);
  storage.setUser(user);
  return user;
}

async function loadUser() {
  if (storage.getToken()) {
    // ? comment for live API
    const data = storage.getUser();
    // const data = await getUser();
    return data;
  }
  return null;
}

async function loginFn(data) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data) {
  const response = await registerWithCompanyEmail(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  storage.clearToken();
  storage.clearUser();
  window.location.assign(window.location.origin);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
