import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { ChatsRoutes } from '@/features/messaging';
import { lazyImport } from '@/utils/lazyImport';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const { SuggestionsRoutes } = lazyImport(
  () => import('@/features/suggestions'),
  'SuggestionsRoutes'
);
const { AnalyticsRoutes } = lazyImport(() => import('@/features/analytics'), 'AnalyticsRoutes');
const { IssuesRoutes } = lazyImport(() => import('@/features/Issues'), 'IssuesRoutes');
const { DeliveriesRoutes } = lazyImport(() => import('@/features/deliveries'), 'DeliveriesRoutes');
const { UsersRoutes } = lazyImport(() => import('@/features/users'), 'UsersRoutes');
const { VendorsRoutes } = lazyImport(() => import('@/features/vendors'), 'VendorsRoutes');
const { CommunitiesRoutes } = lazyImport(
  () => import('@/features/communities'),
  'CommunitiesRoutes'
);
const { NotificationRoutes } = lazyImport(
  () => import('@/features/notifications'),
  'NotificationRoutes'
);
const { PoliciesRoutes } = lazyImport(() => import('@/features/policies'), 'PoliciesRoutes');
const { Dashboard } = lazyImport(() => import('@/features/misc'), 'Dashboard');
const { Settings } = lazyImport(() => import('@/features/settings'), 'Settings');
const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="sm" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/suggestions/*', element: <SuggestionsRoutes /> },
      { path: '/analytics/*', element: <AnalyticsRoutes /> },
      { path: '/issues/*', element: <IssuesRoutes /> },
      { path: '/deliveries/*', element: <DeliveriesRoutes /> },
      { path: '/users/*', element: <UsersRoutes /> },
      { path: '/vendors/*', element: <VendorsRoutes /> },
      { path: '/communities/*', element: <CommunitiesRoutes /> },
      { path: '/notifications/*', element: <NotificationRoutes /> },
      { path: '/policies/*', element: <PoliciesRoutes /> },
      { path: '/live-sessions/*', element: <ChatsRoutes /> },
      { path: '/profile', element: <Profile /> },
      { path: '/settings', element: <Settings /> },
      { path: '/', element: <Dashboard /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
