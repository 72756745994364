import React from 'react';

export function Pagination({ postsPerPage, totalPosts, paginateFront, paginateBack, currentPage }) {
  const showing = currentPage * postsPerPage - 6;
  console.log(showing);
  return (
    <div className="py-2">
      <div className="mb-3">
        <p className="text-xs text-gray-g7">
          Showing <span className="font-medium">{showing}</span> to
          <span className="font-medium"> {currentPage * postsPerPage} </span> of{' '}
          <span className="font-medium"> {totalPosts} </span> results
        </p>
      </div>
      <nav className="block"></nav>
      <div>
        <nav
          className="relative z-0 inline-flex rounded-2xl shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <a
            onClick={() => {
              paginateBack();
            }}
            href="#"
            className={`relative inline-flex items-center px-2 py-1 rounded-l-md shadow bg-white dark:bg-gray-g5 text-xs font-medium text-orange-o10 ${
              currentPage === 1 ? 'opacity-50 pointer-events-none cursor-not-allowed' : ''
            }`}
          >
            <span>Previous</span>
          </a>

          <a
            onClick={() => {
              paginateFront();
            }}
            href="#"
            className={`relative inline-flex items-center px-2 py-1 rounded-r-md shadow bg-white dark:bg-gray-g5 text-xs font-medium text-orange-o10 ${
              showing === totalPosts ? 'opacity-50 pointer-events-none cursor-not-allowed' : ''
            }`}
          >
            <span>Next</span>
          </a>
        </nav>
      </div>
    </div>
  );
}
