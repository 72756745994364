import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';

export const getIssues = () => {
  return axios.get('/issue');
};

export const useIssues = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['issues'],
    queryFn: () => getIssues(),
  });
};
