export const TRANSLATIONS_EN = {
  // languages
  en: 'English',
  sw: 'Swahili',
  fr: 'French',

  // landing page
  welcome: 'Only SpareMonger! Team is allowed access to this panel',
  getStarted: 'Get Started', // button

  // login page
  login: 'Login to Account',
  email: 'Email Address', // label
  password: 'Password', // label
  signIn: 'Sign In', // button
  forgotPassword: 'Forgot Password?', // link
  noAccount: 'Register Account?', // link

  // register page
  register: 'Register New Account',
  registerCaption: 'Please use your SpareMonger! Email Address',
  registerBtn: 'Register', // button
  alreadyHaveAccount: 'Already have an account?', // link

  // forgot password page
  forgotPasswordCaption: 'Forgot Password',
  forgotPasswordBtn: 'Recover Password', // button
  goToLogin: 'Go to Login', // link

  // settings page
  settings: 'Settings',

  // policies
  policies: 'Policies',

  // main layout
  home: 'Home',
  analytics: 'Analytics',
  suggestions: 'Suggestions',
  issues: 'Issues',
  deliveries: 'Deliveries',
  team: 'Team',
  communities: 'Communities',
  vendors: 'Vendors',
  myAccount: 'My Account',
  signout: 'Sign out',

  // dashboard page
  hello: 'Hello',
  customers: 'Customers',
  orders: 'Orders',
  currentIssues: 'Current Issues',
  noIssues: 'Great! There are no issues currently',

  // analytics page
  cars: 'Cars',
  manufacturers: 'Manufacturers',
  models: 'Models',
  spareParts: 'Spare parts',
  users: 'Users',
  requests: 'Requests',
  pending: 'Pending',
  delivered: 'Delivered',
  sales: 'Sales',
  completed: 'Completed',
  failed: 'Failed',
  returned: 'Returned',

  // table
  issue: 'Issue',

  // suggestions
  create: 'Create',
  writeNewSuggestion: 'Write New Suggestion',
  submit: 'Submit',
  delete: 'Delete',
  sureToDelete: 'Are you sure you want to delete this suggestion?',
  suggestion: 'Suggestion',
  name: 'Name',
  created: 'Created',
  update: 'Update',
  cancel: 'Cancel',
  content: 'Content',

  language: 'Language',

  // notifications
  notifications: 'Notifications',
  notificationsFromPlatform: 'Notifications From Platform',
  customizeNotifications: 'Customize notifications coming from the entire platform.',
  getNotifiedIssueCreated: 'Get notified when new issue is created',
  deliveriesAlert: 'Deliveries Alerts',
  getNotifiedDelivery: 'Get notified when a delivery is completed successfully, or fails.',
  vendorAlerts: 'Vendor Alerts',
  getNotifiedVendorCreated: 'Get notified when a vendor registers, or deletes account.',
  getSuggestions: 'Get notified when new new suggestion is created',
  getNotifiedCommunities: 'Get notified when new community is created',

  // vendors
  banned: 'Banned',
  businessLocation: 'Business Location',
  businessLicense: 'Business License',
  dateOfRegistration: 'Date of Registration',
  taxIdentificationNumber: 'Tax Identification Number',
  inventory: 'Inventory',
  returns: 'Returns',
  banVendor: 'Ban Vendor',
  confirmBan: 'Are you sure you want to ban this vendor?',

  privacyPolicy: 'Privacy Policy',
  returnPolicy: 'Return Policy',
  termsOfUse: 'Terms of Use',

  chat: 'Live Chat',
  carManufacturers: 'Car Manufacturers',
  carModels: 'Car Models',
};
