import clsx from 'clsx';

export const FieldWrapper = (props) => {
  const { icon, className, error, children } = props;
  return (
    <div>
      <label className={clsx('relative text-gray-400 focus-within:text-gray-600 block', className)}>
        <label
          className={clsx(
            'pointer-events-none w-8 h-8 absolute top-7 transform -translate-y-1/2 left-3 text-gray-g6',
            className
          )}
        >
          {icon}
        </label>

        {children}
      </label>
      {error?.message && (
        <div
          role="alert"
          aria-label={error.message}
          className="text-xs mt-1 ml-4 text-gray-g6 rounded-2xl"
        >
          {error.message}
        </div>
      )}
    </div>
  );
};
