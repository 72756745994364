import { Route, Routes } from 'react-router-dom';

import Chats from './Chats';

export const ChatsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Chats />} />
      {/* <Route path=":issueId" element={<Issue />} /> */}
    </Routes>
  );
};
