export const TRANSLATIONS_SW = {
  // languages
  en: 'Kiingereza',
  sw: 'Swahili',
  fr: 'Kifaransa',

  // landing page
  welcome: 'SpareMonger pekee! Timu inaruhusiwa kufikia kidirisha hiki',
  getStarted: 'Anza', // button

  // login page
  login: 'Ingia kwa akaunti',
  email: 'Barua pepe', // label
  password: 'Nenosiri', // label
  signIn: 'Weka sahihi', // button
  forgotPassword: 'Umesahau nywila?', // link
  noAccount: 'Usajili Akaunti?', // link

  // register page
  register: 'Sajili Akaunti Mpya',
  registerCaption: 'Tafadhali tumia SpareMonger yako! Barua pepe',
  registerBtn: 'Sajili', // button
  alreadyHaveAccount: 'Je, tayari una akaunti?', // link

  // forgot password page
  forgotPasswordCaption: 'Umesahau nywila',
  forgotPasswordBtn: 'Rejesha Nenosiri', // button
  goToLogin: 'Nenda kwa Ingia', // link

  // settings page
  settings: 'Mipangilio',

  // policies
  policies: 'Sera',

  // main layout
  home: 'Nyumbani',
  analytics: 'Ujumbe',
  suggestions: 'Maoni',
  issues: 'Matatizo',
  deliveries: 'Matukio',
  team: 'Timu',
  communities: 'Jamii',
  vendors: 'Vendors',
  myAccount: 'Akaunti Yangu',
  signout: 'Toka',

  // dashboard page
  hello: 'Habari',
  customers: 'Wateja',
  orders: 'Mazao',
  currentIssues: 'Matatizo ya sasa',
  noIssues: 'Bidhaa! Hivi sasa hakuna matatizo',

  // analytics page
  cars: 'Magari',
  manufacturers: 'Wazalishaji',
  models: 'Mifano',
  spareParts: 'vipuri',
  users: 'Watumiaji',
  requests: 'Maombi',
  pending: 'Inasubiri',
  delivered: 'Mikononi',
  sales: 'Mauzo',
  completed: 'Maliza',
  failed: 'Imekamilika',
  returned: 'Imerejeshwa',

  // table
  issue: 'Matatizo',

  // suggestions
  create: 'Tengeneza',
  writeNewSuggestion: 'Andika Maoni Mpya',
  submit: 'Wasilisha',
  delete: 'Futa',
  sureToDelete: 'Je, unataka kufuta maoni hii?',
  suggestion: 'Maoni',
  name: ' Jina',
  created: 'Iliyotengenezwa',
  update: 'Sasisha',
  cancel: 'Futa',
  content: 'Maudhui',

  language: 'Lugha',

  // notifications
  notifications: 'Arifa',
  notificationsFromPlatform: 'Arifa kutoka platformi',
  customizeNotifications: 'Tumia arifa kwa kuweka upya',
  getNotifiedIssueCreated: 'Arifa kutoka kwa matatizo yanayotengenezwa',
  deliveriesAlert: 'Arifa ya Matukio',
  getNotifiedDelivery: 'Arifa kutoka kwa matukio yaliyofanyika, au imekamilika',
  vendorAlerts: ' Arifa ya Vendors',
  getNotifiedVendorCreated: 'Arifa kutoka kwa Vendors yanayotengenezwa',
  getSuggestions: 'Arifa kutoka kwa maoni yanayotengenezwa',
  getNotifiedCommunities: 'Arifa kutoka kwa jamii yanayotengenezwa',

  // vendors
  banned: 'Marufuku',
  businessLocation: 'Mahali',
  businessLicense: 'Nyaraka',
  dateOfRegistration: 'Tarehe ya usajili',
  taxIdentificationNumber: 'Nambari ya kitambulisho',
  inventory: 'Inventari',
  returns: 'Matukio',
  banVendor: 'Futa Vendor',
  confirmBan: 'Je, unataka kufuta vendor hii?',

  privacyPolicy: 'Sera ya Ulinzi',
  returnPolicy: 'Sera ya Matukio',
  termsOfUse: 'Sera ya Kupumzika',

  chat: 'Mazungumzo',

  carManufacturers: 'Wazalishaji',
  carModels: 'Mifano',
};
