import '@/locales/i18n';
import { Button, Logo } from '@/components/Elements';
import { Head } from '@/components/Head';
import { useAuth } from '@/lib/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const Landing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleStart = () => {
    if (user) {
      navigate('/app');
    } else {
      navigate('/auth/login');
    }
  };

  return (
    <>
      <Head description="Welcome to Help Desk" />
      <div className="bg-white dark:bg-gray-g3 h-[100vh] flex items-center">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="flex justify-center">
            <Logo />
          </div>
          <p className="dark:text-gray-g9 mt-4">{t('welcome')}.</p>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-2xl">
              <Button onClick={handleStart}>{t('getStarted')}</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
