// import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { useMutation } from 'react-query';

export const registerWithCompanyEmail = () => {
  // const { email } = data;
  // return axios.post('/user/register', email);
};

export const useRegisterWithCompanyEmail = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (newUser) => {
      await queryClient.cancelQueries(['users']);

      const previousUsers = queryClient.getQueryData(['users']);

      queryClient.setQueryData(['users'], [...(previousUsers || []), newUser.data]);

      return { previousUsers };
    },
    onError: (_, __, context) => {
      if (context?.previousUsers) {
        queryClient.setQueryData(['users'], context.previousUsers);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      addNotification({
        type: 'success',
        title: 'Check your email for your password',
      });
    },
    ...config,
    mutationFn: registerWithCompanyEmail,
  });
};
