import { ArchiveIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Pagination } from '../Pagination/Pagination';

export const Table = ({ data, columns }) => {
  // eslint-disable-next-line no-unused-vars
  const [posts, setPosts] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    setPosts(data);
  }, [data]);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginateFront = () => setCurrentPage(currentPage + 1);
  const paginateBack = () => setCurrentPage(currentPage - 1);

  if (!posts?.length) {
    return (
      <div className="bg-white dark:bg-gray-g3 text-gray-500 h-80 flex justify-center items-center flex-col">
        <ArchiveIcon className="h-16 w-16 text-gray-g8" />
        <h4 className="text-gray-g7">You have no entries at the moment</h4>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className=" overflow-hidden  sm:rounded-lg">
            <table
              id="dataTable"
              className="w-full"
              style={{
                borderCollapse: 'separate',
                borderSpacing: '0 15px',
              }}
            >
              <thead className="bg-gray-50">
                <tr className="row">
                  {/* <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all"
                        type="checkbox"
                        className="w-4 h-4 text-orange-o10 bg-gray-g8 border-gray-g3 rounded dark:bg-gray-g4 dark:border-gray-g5"
                      />
                      <label htmlFor="checkbox-all" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th> */}
                  {columns.map((column, index) => (
                    <th
                      colIndex={index}
                      key={column.title + index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium dark:text-gray-g9 uppercase tracking-wider"
                    >
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((entry, entryIndex) => (
                  <tr key={entry?.id || entryIndex} className="bg-gray-g9 row dark:bg-gray-g5">
                    {/* <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-table-1"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label htmlFor="checkbox-table-1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td> */}
                    {columns.map(({ Cell, field, title }, columnIndex) => (
                      <td
                        key={title + columnIndex}
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium dark:text-gray-g9"
                      >
                        {Cell ? <Cell entry={entry} /> : entry[field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!currentPosts?.length ? (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className="bg-gray-g9 row dark:bg-gray-g5 py-4 rounded-2xl flex justify-center">
                        <h4 className="text-gray-g7">You have no more entries</h4>
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
            <div className="flex justify-end">
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={posts.length}
                paginateFront={paginateFront}
                paginateBack={paginateBack}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.func,
      field: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};
