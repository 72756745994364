import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { TRANSLATIONS_EN } from './en/locales';
import { TRANSLATIONS_FR } from './fr/locales';
import { TRANSLATIONS_SW } from './sw/locales';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      sw: {
        translation: TRANSLATIONS_SW,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
    },
  });

export { i18n };
