import { useTranslation } from 'react-i18next';

import { ForgotPasswordForm } from '../components/ForgotPasswordForm';
import { Layout } from '../components/Layout';

export const ForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('forgotPasswordCaption')}>
      <ForgotPasswordForm />
    </Layout>
  );
};
