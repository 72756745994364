export const TRANSLATIONS_FR = {
  // languages
  en: 'Anglais',
  sw: 'Swahili',
  fr: 'Français',

  // landing page
  welcome: `Seulement SpareMonger! L'équipe est autorisée à accéder à ce panneau`,
  getStarted: 'Commencer', // button

  // login page
  login: 'Connectez-vous au compte',
  email: 'Adresse e-mail', // label
  password: 'Mot de passe', // label
  signIn: `S'identifier`, // button
  forgotPassword: 'Mot de passe oublié?', // link
  noAccount: 'Créer un compte?', // link

  // register page
  register: 'Créer un nouveau compte',
  registerCaption: 'Veuillez utiliser votre SpareMonger ! Adresse e-mail',
  registerBtn: `S'inscrire`, // button
  alreadyHaveAccount: 'Vous avez déjà un compte?', // link

  // forgot password page
  forgotPasswordCaption: 'Mot de passe oublié',
  forgotPasswordBtn: 'Récupérer mot de passe', // button
  goToLogin: 'Aller à Connexion', // link

  // settings page
  settings: 'Réglages',

  // policies
  policies: 'Politiques',

  // main layout
  home: 'Accueil',
  analytics: 'Analyse',
  suggestions: 'Suggestions',
  issues: 'Problèmes',
  deliveries: 'Livraisons',
  team: 'Équipe',
  communities: 'Communautés',
  vendors: 'Vendeurs',
  myAccount: 'Mon compte',
  signout: 'Déconnexion',

  // dashboard page
  hello: 'Bonjour',
  customers: 'Les clients',
  orders: 'Ordres',
  currentIssues: `Questions d'actualité`,
  noIssues: `Génial! Il n'y a pas de problèmes actuellement`,

  // analytics page
  cars: 'Voitures',
  manufacturers: 'Fabricants',
  models: 'Modèles',
  spareParts: 'Pièces détachées',
  users: 'Utilisateurs',
  requests: 'Demandes',
  pending: 'En attente',
  delivered: 'Livré',
  sales: 'Ventes',
  completed: 'Terminé',
  failed: 'Échoué',
  returned: 'Retourné',

  // table
  issue: 'Question',

  // suggestions
  create: 'Créer',
  writeNewSuggestion: 'Écrire une nouvelle suggestion',
  submit: 'Soumettre',
  delete: 'Supprimer',
  sureToDelete: 'Êtes-vous sûr de vouloir supprimer cette suggestion?',
  suggestion: 'Suggestion',
  name: 'Nom',
  created: 'Créé',
  update: 'Mettre à jour',
  cancel: 'Annuler',
  content: 'Contenu',

  language: 'Langue',

  // notifications
  notifications: 'Notifications',
  notificationsFromPlatform: 'Notifications de la plateforme',
  customizeNotifications: 'Personnaliser les notifications',
  getNotifiedIssueCreated: "Recevoir des notifications lorsqu'une nouvelle question est créée",
  deliveriesAlert: 'Alertes de livraison',
  getNotifiedDelivery:
    'Recevoir des notifications lorsque une livraison est terminée avec succès ou échouée',
  vendorAlerts: 'Alertes de vendeur',
  getNotifiedVendorCreated: "Recevoir des notifications lorsqu'un nouveau vendeur est créé",
  getSuggestions: 'Recevoir des suggestions',
  getNotifiedCommunities: "Recevoir des notifications lorsqu'une nouvelle communauté est créée",

  // vendors
  banned: 'Banni',
  businessLocation: "Emplacement de l'entreprise",
  businessLicense: "Licence d'entreprise",
  dateOfRegistration: "Date d'inscription",
  taxIdentificationNumber: "Numéro d'identification fiscale",
  inventory: 'Inventaire',
  returns: 'Retours',
  banVendor: 'Bannir le vendeur',
  confirmBan: 'Êtes-vous sûr de vouloir bannir ce vendeur?',

  privacyPolicy: 'Politique de confidentialité',
  returnPolicy: 'Politique de retour',
  termsOfUse: " Conditions d'utilisation",

  chat: 'Chat en direct',

  carManufacturers: 'Fabricants de voitures',
  carModels: 'Modèles de voitures',
};
