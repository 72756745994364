/* eslint-disable jsx-a11y/no-autofocus */
import { ContentLayout } from '@/components/Layout';
import { API_URL } from '@/config';
import storage from '@/utils/storage';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import socketIOClient from 'socket.io-client';

let allUsers = [];
let allMessages = [];
let allSelectedUser = {};

const ENDPOINT = API_URL || 'http://localhost:5001';

const Chats = () => {
  const [socket, setSocket] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const uiMessagesRef = useRef(null);
  const [messageBody, setMessageBody] = useState('');
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);

  const { t } = useTranslation();

  const user = storage.getUser();

  useEffect(() => {
    if (uiMessagesRef.current) {
      uiMessagesRef.current.scrollBy({
        top: uiMessagesRef.current.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    }

    if (!socket) {
      const sk = socketIOClient(ENDPOINT);
      setSocket(sk);
      sk.emit('onLogin', {
        id: user.id,
        name: user.firstName,
        isAdmin: true,
      });
      sk.on('message', (data) => {
        if (allSelectedUser.id === data.id) {
          allMessages = [...allMessages, data];
        } else {
          const existUser = allUsers.find((user) => user.id === data.id);
          if (existUser) {
            allUsers = allUsers.map((user) =>
              user.id === existUser.id ? { ...user, unread: true } : user
            );
            setUsers(allUsers);
          }
        }
        setMessages([...allMessages, { name: data.name }]);
      });
      sk.on('updateUser', (updatedUser) => {
        const existUser = allUsers.find((user) => user.id === updatedUser.id);
        if (existUser) {
          allUsers = allUsers.map((user) => (user.id === existUser.id ? updatedUser : user));
          setUsers(allUsers);
        } else {
          allUsers = [...allUsers, updatedUser];
          setUsers(allUsers);
        }
      });
      sk.on('listUsers', (updatedUsers) => {
        allUsers = updatedUsers;
        setUsers(allUsers);
      });
      sk.on('selectUser', (user) => {
        allMessages = user.messages;
        setMessages(allMessages);
      });
    }
  }, [messages, socket, users, user]);

  const selectUser = (user) => {
    allSelectedUser = user;
    setSelectedUser(allSelectedUser);
    const existUser = allUsers.find((x) => x.id === user.id);
    if (existUser) {
      allUsers = allUsers.map((x) => (x.id === existUser.id ? { ...x, unread: false } : x));
      setUsers(allUsers);
    }
    socket.emit('onUserSelected', user);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!messageBody.trim()) {
      alert('Error. Please type message.');
    } else {
      allMessages = [...allMessages, { body: messageBody, name: user.firstName }];
      setMessages(allMessages);
      setMessageBody('');
      setTimeout(() => {
        socket.emit('onMessage', {
          body: messageBody,
          name: user.firstName,
          isAdmin: true,
          id: selectedUser.id,
        });
      }, 1000);
    }
  };

  return (
    <ContentLayout title={t('chat')}>
      <div className="flex mt-4">
        <div className="main flex-1 flex flex-col">
          <div className="flex-1 flex h-full">
            <div className="sidebar hidden lg:flex w-1/3 flex-2 flex-col pr-6">
              {/* <div className="search flex-2 pb-6 px-2 mb-4">
                <input
                  type="text"
                  className="outline-none py-2 block w-full bg-transparent border-b-2 border-gray-200"
                  placeholder="Search"
                />
              </div> */}
              <div className="bg-orange-o10 rounded-2xl text-white p-4 mb-4">
                <span>Chats</span>
              </div>
              <div className="flex-1 h-full overflow-auto px-2">
                {users.filter((x) => x.id !== user.id).length === 0 && (
                  <div className="mx-4 text-gray-g6 text-sm">No Online User Found</div>
                )}
                {users
                  .filter((x) => x.id !== user.id)
                  .map((user) => (
                    <a
                      href="#"
                      key={user.id}
                      onClick={() => selectUser(user)}
                      className="entry cursor-pointer transform hover:scale-105 duration-300 transition-transform bg-white mb-4 rounded p-4 flex shadow-md"
                    >
                      <div className="flex-2">
                        <div className="w-12 h-12 relative">
                          <div className="w-12 h-12 rounded-full bg-gray-g3 mx-auto"></div>
                          <span
                            className={user.unread ? 'unread' : user.online ? 'online' : 'offline'}
                          />
                          <span className="absolute w-4 h-4 bg-states-success rounded-full right-0 bottom-0 border-2 border-white"></span>
                        </div>
                      </div>
                      <div className="flex-1 px-2">
                        <div className="truncate w-32">
                          <span className="text-gray-800">{user.name}</span>
                        </div>
                        <div>
                          <div className="bg-gray-g8 w-10 rounded-2xl h-4"></div>
                        </div>
                      </div>
                      <div className="flex-2 text-right">
                        <div>
                          <div className="bg-gray-g8 w-10 rounded-2xl h-2"></div>
                        </div>
                        <div>
                          <small className="text-xs bg-red-500 text-white rounded-full h-6 w-6 leading-6 text-center inline-block">
                            23
                          </small>
                        </div>
                      </div>
                    </a>
                  ))}
              </div>
            </div>
            {!selectedUser.id ? (
              <div>Select a user to start chat</div>
            ) : (
              <div className="chat-area flex-1 flex flex-col">
                <div className="bg-gray-g9 rounded-2xl text-orange-o10 p-4 mb-4">
                  <span>{selectedUser.name}</span>
                </div>
                <div
                  className="messages border border-gray-g8 rounded-2xl p-4 flex-1 overflow-auto"
                  ref={uiMessagesRef}
                >
                  {messages.length === 0 && <div className="message mb-4 flex">No message.</div>}
                  {messages.map((msg, index) => (
                    <div key={index} className="message mb-4 flex">
                      <div className="flex-2">
                        <div className="w-12 h-12 relative">
                          <div className="w-12 h-12 rounded-full bg-gray-g3 mx-auto text-center grid items-center">
                            <span className="text-white text-md font-bold">
                              {msg?.name.charAt(0)}
                            </span>
                          </div>
                          <span
                            className={user.unread ? 'unread' : user.online ? 'online' : 'offline'}
                          />
                          <span className="absolute w-4 h-4 bg-states-success rounded-full right-0 bottom-0 border-2 border-white"></span>
                        </div>
                      </div>
                      <div className="flex-1 px-2 mt-1">
                        <div className="inline-block bg-gray-g8 rounded-full p-2 px-6 text-gray-700">
                          <span>{msg.body}</span>
                        </div>
                        <div className="pl-4">
                          <small className="text-gray-500">{msg.date}</small>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <form onSubmit={submitHandler} className="flex-2 pt-4 pb-10">
                  <div className="write bg-white shadow flex rounded-lg">
                    <div className="flex-3 flex content-center items-center text-center p-4 pr-0">
                      <span className="block text-center text-gray-400 hover:text-gray-800">
                        <svg
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          className="h-6 w-6"
                        >
                          <path d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                      </span>
                    </div>
                    <div className="flex-1">
                      <input
                        name="message"
                        className="w-full block outline-none py-4 px-4 bg-transparent"
                        placeholder="Type a message..."
                        value={messageBody}
                        onChange={(e) => setMessageBody(e.target.value)}
                        autoFocus
                      />
                    </div>
                    <div className="flex-2 w-32 p-2 flex content-center items-center">
                      <div className="flex-1 text-center">
                        <span className="text-gray-400 hover:text-gray-800">
                          <span className="inline-block align-text-bottom">
                            <svg
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              className="w-6 h-6"
                            >
                              <path d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path>
                            </svg>
                          </span>
                        </span>
                      </div>
                      <div className="flex-1">
                        <button
                          type="submit"
                          className="bg-blue-400 w-10 h-10 rounded-full inline-block"
                        >
                          <span className="inline-block align-text-bottom">
                            <svg
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              className="w-4 h-4 text-white"
                            >
                              <path d="M5 13l4 4L19 7"></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};

Chats.propTypes = {};

export default Chats;
