import * as React from 'react';

import { useAuth } from './auth';

export const ROLES = {
  ALPHA: 'alpha',
  BETA: 'beta',
};

export const POLICIES = {
  'comment:delete': (user, comment) => {
    if (user.userLevel === 'alpha') {
      return true;
    }

    if (user.userLevel === 'beta' && comment.authorId === user.id) {
      return true;
    }

    return false;
  },
};

export const useAuthorization = () => {
  const { user } = useAuth();

  if (!user) {
    throw Error('User does not exist!');
  }

  const checkAccess = React.useCallback(
    ({ allowedRoles }) => {
      if (allowedRoles && allowedRoles.length > 0) {
        return allowedRoles?.includes(user.userLevel);
      }

      return true;
    },
    [user.userLevel]
  );

  return { checkAccess, userLevel: user.userLevel };
};

export const Authorization = ({
  policyCheck,
  allowedRoles,
  forbiddenFallback = null,
  children,
}) => {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  if (typeof policyCheck !== 'undefined') {
    canAccess = policyCheck;
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};
