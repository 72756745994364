import * as React from 'react';

export const useDarkMode = () => {
  const currentTheme = localStorage.getItem('theme') ?? 'light';
  const [theme, setTheme] = React.useState(currentTheme);
  const colorTheme = theme === 'light' ? 'dark' : 'light';

  React.useEffect(
    () => {
      localStorage.setItem('theme', theme);
      const root = window.document.documentElement;
      root.classList.remove(colorTheme);
      root.classList.add(theme);
    },
    [colorTheme, theme],
    colorTheme
  );
  return [colorTheme, setTheme];
};
