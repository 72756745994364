import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';

export const InputField = (props) => {
  const {
    type = 'text',
    icon,
    className,
    ref,
    value,
    placeholder,
    registration,
    error,
    caption,
  } = props;
  return (
    <FieldWrapper icon={icon} error={error}>
      <input
        type={type}
        className={clsx(
          'form-input rounded-2xl py-3 px-4 bg-orange-o1 placeholder-gray-g6 dark:bg-gray-g3 text-gray-g3 dark:text-gray-g8 appearance-none w-full block pl-14 focus:outline-none',
          className
        )}
        value={value}
        ref={ref}
        placeholder={placeholder}
        {...registration}
      />
      <span className="text-xs mx-4 text-gray-g6">{caption}</span>
    </FieldWrapper>
  );
};
