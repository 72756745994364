import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';

export const TextAreaField = (props) => {
  const { label, className, icon, placeholder, registration, error, caption } = props;
  return (
    <FieldWrapper icon={icon} label={label} error={error}>
      <textarea
        rows="10"
        className={clsx(
          'appearance-none block w-full px-3 py-2 rounded-md shadow-sm bg-orange-o1 placeholder-gray-g6 dark:bg-gray-g3 text-gray-500 sm:text-sm',
          className
        )}
        placeholder={placeholder}
        {...registration}
      />
      <span className="text-xs mx-4 text-gray-g6">{caption}</span>
    </FieldWrapper>
  );
};
