import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import PropTypes from 'prop-types';
import { User, Lock } from 'react-feather';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as z from 'zod';

const schema = z.object({
  email: z.string().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
});

export const LoginForm = ({ onSuccess }) => {
  const { t } = useTranslation();
  const { login, isLoggingIn } = useAuth();

  return (
    <div>
      <Toaster />
      <Form
        onSubmit={async (values) => {
          await login(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              icon={<User />}
              placeholder={t('email')}
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              icon={<Lock />}
              placeholder={t('password')}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button isLoading={isLoggingIn} type="submit" className="w-full text-gray-g9">
                {t('signIn')}
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex justify-between">
        <div className="text-sm">
          <Link to="../forgot-password" className="font-medium text-orange-o10">
            {t('forgotPassword')}
          </Link>
        </div>
        {/* <div className="text-sm">
          <Link to="../register" className="font-medium text-orange-o10">
            {t('noAccount')}
          </Link>
        </div> */}
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
